@import "variables";

// Set dropdown width
.ant-dropdown {
  width: 200px;
  .ant-dropdown-menu-item:hover {
    background-color: $light-primary;
  }
}

// Tabs Next, and Previous icon size change
.ant-tabs-tab-prev-icon-target, .ant-tabs-tab-next-icon-target {
  font-size: 20px !important;
}

// Fixed ant tabs header
.ant-tabs {
  display: flex;
  flex-direction: column;
  height: 100%;
  .ant-tabs-bar {
    margin: 0px;
    flex-shrink: 0;
    flex-grow: 0;
  }
  .ant-tabs-top-content {
    height: calc(100% - 50px);
    .ant-tabs-tabpane {
      height: 100%;
      overflow: auto;
    }
  }
}

// Ant message overrides for array of errors
.ant-message-error {
  position: relative;
  .anticon-close-circle {
    position: absolute !important;
    top: 1px !important;
    left: 1px;
  }
  span {
    margin-left: 25px !important;
    ul {
      display: inline-block;
      text-align: left;
    }
  }
}

// Table Responsiveness
.ant-layout-content {
  height: 100%;
  .ant-table {
    height: 100%;

    .ant-table-header {
      border: 1px solid $border-light-color;
    }

    .ant-table-thead {
      border-top: 1px solid $border-light-color;
    }

    .ant-table-placeholder, .ant-table-body {
      border: 1px solid $border-light-color;
      border-top: none;
    }

    .ant-table-body {
      overflow: auto;

      table {
        border-collapse: collapse !important;
      }

      // Last row border hiding
      .ant-table-row {
        &:last-child {
          & > td {
            border-bottom: none;
          }
        }
      }
    }
  }
}

// Overrides button disabled color
.ant-btn-primary {
  &:disabled {
    background-color: $primary !important;
    border-color: $primary !important;
    color: $white !important;
    opacity: 0.4;
  }
}

//Overrides for space issue
.ant-modal-body {
  .ant-row{
    @media (min-width: 767px) {
      display: flex;
      .ant-form-item-control-wrapper {
        margin: auto;
      }
    }
  }
  .ant-form-item-label {
    white-space: pre-wrap;
    line-height: 2;
    padding-top: 5px;
    margin-right: 5px;
  }
  .ant-form-item:last-child {
    margin-bottom: 0px;
  }
}

// Form item no bottom margin
.form-customize {
  .form-item-customize {
    margin-bottom: 0px;
  }
}

//InputTextArea notes modified status
.character-count {
  display: flex;
  justify-content: flex-end;
  font-size: 12px;
  line-height: 20px;
  color: rgba(0, 0, 0, 0.45);
  font-weight: 400;
}

.poly-notes-overlay {
  .ant-popover-inner-content {
    overflow: auto;
  }
}

#suggested_changes {
  line-height: 1.5;
  margin-bottom: 8px;
}

.notes-modified {
  line-height: 24px;
  color: $info-grey;
  .notes-modified-data {
    display: inline-block;
    .popover {
      color: #0B71B2 !important;
      cursor: pointer;
      &.see-more {
        display: block;
      }
    }
  }
}

.notes-popover-container {
  .ant-popover-inner {
    max-height: 200px;
    overflow: scroll;
  }
  .notes-popover-item {
    padding: 4px 0;
  }
}

//Error message margin
.ant-message-error span ul{
  margin-bottom: 0;
}

//Input Number Spinners
form {
  .ant-input-number-handler-wrap {
    display: none;
  }
}

//Edit member profile Checkbox input size
.ant-checkbox {
  .ant-checkbox-inner {
    height: 20px;
    width: 20px;
    &::after{
      margin-left: 1px;
    }
  }
}

//Member photo in View profile
.ant-avatar > img{
  object-fit:contain;
}

.ant-upload-select-text {
  width: 100%;
  button {
    width: 100%;
  }
}

.ant-upload-list-item-info {
  background-color: transparent !important;
  :hover {
    a:link {
        text-decoration: underline;
    }
  }
}

.ant-upload-list-item-card-actions  {
  a {
    .anticon-delete {
      color: $error-color;
    }
  }
}

.ant-upload-list-item-error {
  display: none;
}

.table-page-customization {
  overflow: hidden !important;
  height: 100%;
  .ant-table-wrapper {
    height: 100%;
    max-height: 100%;
    .ant-spin-nested-loading {
      height: 100%;
      max-height: 100%;
      .ant-spin-container {
        height: 100%;
        display: flex;
        flex-direction: column;
        .ant-table-fixed-header {
          flex-grow: 1;
          flex-shrink: 1;
          overflow: hidden;
        }
        .ant-table-content {
          height: 100%;
          .ant-table-scroll {
            height: 100%;
            overflow: hidden;
            .ant-table-body {
              height: auto !important;
              max-height: calc(100% - 50px) !important;
            }
          }
        }
        .ant-pagination {
          padding: 16px 0;
          margin: 0px 0px 0px auto;
        }
      }
    }
  }
  .changeRequests {
    padding-bottom: 50px !important
  }
}

.ant-table-content {
  .ant-table-scroll {
    .ant-table-body {
      td {
        white-space: pre-wrap;
      }
    }
  }
}

.tick-mark-icon {
  svg {
    height: 16px;
    width: 16px;
    fill: $primary;
    stroke: $primary;
    stroke-width: 120px;
  }
}

.close-mark-icon {
  svg {
    height: 16px;
    width: 16px;
    fill: $error-color;
    stroke: $error-color;
    stroke-width: 120px;
  }
}

// Icon color change for Edit and Delete
.anticon-edit {
  color: $primary !important;
}
.anticon-delete {
  color: $danger-color !important;
}

// Reduced some spaces
.ant-layout-content {
  margin: 16px 16px 0px 16px !important;
  padding: 10px 20px 0px 20px !important;
}

// Label required symbol at the last
.ant-form-item-required {
  &:before {
    content: '' !important;
    margin-right: 0px !important;
  }
  &:after {
    display: inline-block !important;
    color: $required-color;
    font-size: 14px;
    font-family: SimSun, sans-serif;
    line-height: 1;
    content: '*' !important;
    margin-left: 3px !important;
  }
}

.ant-result {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}