$primary: #0B71B2;
$black: #0B0D0E;
$white: #ffffff;
$dark-grey: #86837E;
$light-primary: #c9e3f4;
$border-color: #d9d9d9;
$outline-color: #2a8bbf;
$input-text-color: rgba(0, 0, 0, 0.65);
$error-color: #ff0000;
$border-light-color: #eff0f2;
$table-header-color: #3E3C3D;
$danger-color: #d44b4b;
$border-style: 1px solid rgba(0, 0, 0, 0.14);
$table-border: 1px solid #eff0f2;
$required-color: #f5222d;
$warning-color: #faad14;
$info-grey: #b0afab;
$label-text-color: rgba(0, 0, 0, 0.85)