@import "../../style/variables";

.loader {
  display: flex !important;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.trigger-dropdown-customize {
  padding-left: 25px;
  padding-right: 25px;
   .ant-btn-default:first-child {
     display: none !important;
   }
  .ant-dropdown-trigger {
    border: none;
    border-radius: 4px !important;
  }
}

.number-format {
  width: 100%;
  height: 32px;
  padding: 4px 11px;
  color: $input-text-color;
  font-size: 14px;
  line-height: 1.5;
  background-color: $white;
  background-image: none;
  border: 1px solid $border-color;
  border-radius: 4px;
  &:focus {
    border-color: $primary;
    border-right-width: 1px !important;
    outline: 0;
    -webkit-box-shadow: 0 0 0 2px rgba(11, 113, 178, 0.2);
    box-shadow: 0 0 0 2px rgba(11, 113, 178, 0.2);
  }
}

//SSN component
.ssn-input-div {
  position: relative;
  outline-color: $white;
  .ssn-in {
    position: absolute;
    z-index: 2;
    opacity: 1;
    width: 100%;
    background: transparent;
    top: 0;
    color: transparent;
    height: 32px;
    border: 1px solid $border-color;
    border-radius: 4px;
    -webkit-transition: all 0.3s;
    transition: all 0.3s;
    caret-color: transparent !important;
    &:focus {
      border-color: $outline-color;
      border-right-width: 1px !important;
      outline: 0;
      -webkit-box-shadow: 0 0 0 2px rgba(11, 113, 178, 0.2);
      box-shadow: 0 0 0 2px rgba(11, 113, 178, 0.2);
    }
    &::selection {
      background: transparent;
      color: transparent;
    }
  }

  .ssn-block {
    position: relative;
    line-height: 34px;
    #ssn-output {
      font-size: 14px;
      padding-left: 10px;
      line-height: 24px;
    }
    .cursor {
      display: none;
      height: 15px;
      width: 1px;
      background: $black;
      position: absolute;
      z-index: 1500;
      top: 9px;
      left: 0px;
      -webkit-animation-name: blink;
      -webkit-animation-duration: 1s;
      -webkit-animation-timing-function: linear;
      -webkit-animation-iteration-count: infinite;
      animation-name: blink;
      animation-duration: 1s;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
    }
    #cursor-position{
      left: 10px;
    }
  }
}

.has-error {
  .ssn-in {
    &.ant-input, &.ant-input:hover {
      background-color: unset;
    }
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
#suggested_changes{
  line-height: 1.8;
  word-break: break-word;
  padding: 10px 5px;
  font-size: 14.5px;
}
#suggestion{
  display: none;
}

.bg-yellow{
  background-color: yellow;
  color: black;
  font-weight: 700;
}
.bg-yellow:hover{
  border: 2px solid black;
  cursor: pointer;
  height: 5px;
  color: black;
}