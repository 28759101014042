@import "variables";
@import "antOverrides";

body {
  margin: 0;
  font-size: .875rem;
  font-weight: 400;
  line-height: 1.5;
  h1, h2, h3, h4, h5, h6 {
    font-family: "Exo",sans-serif;
    font-weight: 500;
    line-height: 1.2;
  }
}

#root {
  height: 100%;
}

/* Hide scrollbar for Chrome, Safari and Opera */
*::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE and Edge */
* {
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.page-container {
  padding-top: 20px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  height: 100%;
  .scroll-container {
    margin: 10px auto auto auto;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 50px;
    max-width: 600px;
    @media (min-width: 1650px) {
      max-width: 750px;
    }
    .ant-btn {
      padding: 0;
      border: none;
      box-shadow: none;
      .anticon {
        svg {
          color: $primary;
        }
      }
    }
    .employee-status-count {
      display: flex;
    }
    .scroll-content {
      display: inline-block;
      transition: transform 1s ease; /* Adjust the duration and easing as needed */
    }
    .employee-status-count-wrap {
      display: flex;
      width: 100%;
      overflow-x: scroll;
      white-space: nowrap;
      border: $border-style;
      padding: 10px 5px;
      border-radius: 10px;
    }
    .count-value {
      display: flex;
      flex-direction: column;
      align-items: center;
      @media (max-width: 768px) {
        margin-bottom: 20px;
      }
      .employee-status-count-text {
        font-size: 12px;
      }
      .employee-status-count-value {
        font-size: 16px;
        font-weight: bold;
      }
      &:not(:last-child) {
        margin-right: 8px;
        padding-right: 6px;
        border-right: 1px solid #e8e8e8;
      }
    }
  }
  .container-header {
    display: flex;
    align-items: center;
    padding: 5px 0px;
    justify-content: space-between;
    i {
      font-size: 20px;
    }
    .date-filter {
      i {
        font-size: unset !important;
      }
    }
    h2 {
      margin-bottom: 0px;
      color: $table-header-color;
    }
    .action-btn {
      text-align: right;
      width: 100%;
      button:first-child {
        margin-right: 10px;
      }
      .ant-row{
        margin: 0;
      }
    }
    button{
      margin-left: 10px;
    }
    .search-container {
      max-width: 100%;
      width:100%;
      display: flex;
      flex-wrap: wrap;
      gap: 10px;
      align-items: center;
      @media (max-width: 500px) {
        gap: 5px;
        .ant-input-search {
          max-width: 200px !important;
        }
        .filter {
          .ant-select {
            max-width: 200px !important;
          }
        }
      }
      @media (max-width: 320px) {
        flex-wrap: wrap;
        .ant-input-search {
          max-width: 150px !important;
        }
        .filter {
          .ant-select {
            max-width: 150px !important;
          }
        }
      }
      .filter, .search-prefix {
        .ant-select {
          width: 200px;
        }
        .ant-select-selection {
          width: -webkit-fill-available;
          display: block;
          .ant-select-selection__clear {
            svg {
              height: 12px;
              width: 12px;
            }
          }
          .ant-select-arrow {
            svg {
              width: 12px;
              position: absolute;
              top: -3px;
              right: 0;
            }
          }
        }
      }
    }
    .date-filter{
      flex: auto;
    }
  }
  .change-request-search {
    display: flex;
    padding-bottom: 15px;
    flex-flow: row;

    .change-request-status-filter{
      margin: 0px 15px;
    }

    .change-request-date-filter{
      width: 230px;
    }
  }
  .container-body {
    padding-top: 15px;
    overflow: auto;
  }
  &.member-profile {
    form {
      position: relative;
      height: 100%;
      display: flex;
      flex-direction: column;
    }
    &.page-container{
      padding-top: 0;
    }
    .container-header {
      display: block;
      padding: 10px 0px;
      border-bottom: $border-style;
      clear: both;
      .search-container {
        display: flex;
        gap: 10px;
        align-items: center;
      }
      h2 {
        float: left;
        padding-top: 5px;
        color: $table-header-color;
      }
      .action-btn {
        float: right;
        width: auto;
        button {
          margin-right: 0;
        }
      }
    }
    .container-body {
      padding-top: 20px;
    }
    .container-footer {
      padding: 15px 0px;
      border-top: $border-style;
      i {
        font-size: 20px;
      }
      h2 {
        margin-bottom: 0px;
        color: $table-header-color;
      }
      .action-btn {
        float: right;
        button {
          margin-left: 10px;
          &:first-child {
            margin-right: 10px;
          }
        }
      }
    }
  }
  &.hide-top-border{
    margin-top: calc(50vh - 9rem);
    height: auto;
    .filter-actions {
      border-top: 0;
      .ant-form-item-children{
        justify-content: center !important;
      }
    }
  }
}

//Restricting characters in input filed
/* For Firefox */
input[type='number'] {
  -moz-appearance:textfield;
}

/* Webkit browsers like Safari and Chrome */
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.layout-content {
  position: relative;
  height: 100%;
}

// If table has no data
.table-info {
  text-align: center;
  padding: 20px 0px;
  color: $dark-grey;
  .link {
    color: $primary;
    cursor: pointer;
  }
}

//For Action buttons in list page
.table-action-btn{
  button {
    padding: 0;
  }
}

.uploaded-file-name{
  :hover{
    text-decoration: underline;
  }
}

.upload {
  .ant-upload-picture-card-wrapper {
    vertical-align: top;
    display: inline-block !important;
    width: auto;
  }
}

//Space between list table
.inner-list-table-container{
  margin-top: 30px;
}

//full-width for InputNumber and DatePicker
.ant-input-number, .ant-calendar-picker, .avatar-preview  {
    &.full-width {
      width: 100%;
    }
}

//Back to Benefits btn
.back-btn {
  width: 125px;
  margin-bottom: 10px;
  & .ant-btn {
    padding: 0;
  }
  :hover {
    span {
      text-decoration: underline;
    }
  }
}

//User and Memberlist search widget
.search-widget, .search-container .filter{
  max-width: 200px;
}
.search-widget-with-prefix {
  max-width: 300px;
  .search-prefix{
    width: 100px;
  }
  svg {
    opacity: 0.6;
    &:hover{
      opacity: 1;
    }
  }
}

//highlight first row of listpage.
.highlight-first-row{
  tr {
    &:first-child {
      font-weight: 200;
      color: $black;
      opacity: 1;
    }
    opacity: 0.7;
  }
}

.truncate-overflow {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.multiple-table-page {
  overflow: visible;
  &.analytics-page {
    padding-top: 0px;
  }
  .individual-content {
    margin-top: 10px;
    margin-bottom: 35px;
    .container-body {
      padding-top: 8px;
    }
  }
}

.table-bottom-padding {
  padding-bottom: 20px;
}

.change-request-header {
  display: flex;
  color: $black;
  padding: 10px 0 10px 0;
  font-weight: 500;
  gap: 57px;
  div {
    width: 200px;
  }
}

.table-column {
  min-width: 125px;
  max-width: 350px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: normal;
  &.exceed-dot {
    white-space: nowrap;
  }
}

.ant-table-layout-fixed  {
  table {
    table-layout: unset !important;
  }
}

@media (max-width: 480px) {
  .hide-element {
    display: none !important;
  }
  //Fix for zoom issue only in IOS(known issue)
  input {
    font-size: 16px !important;
  }
  .ssn-block {
    #ssn-output {
      font-size: 16px !important;
    }
  }
  // Reduced space for Mobile
  .ant-layout-content {
    margin: 16px 0px 0px 0px !important;
    padding-top: 0px !important;
  }
  // ant-modal top is reduced for mobile
  .ant-modal-wrap {
    & .ant-modal {
        top: 10px;
        margin: 0 auto;
    }
  }

// Ant page loader size reduced
#nprogress {
    .spinner {
      top: 5px !important;
      right: 5px !important;
    }
    .spinner-icon {
      width: 10px !important;
      height: 10px !important;
    }
  }
}

//ant-select group Options
.ant-select-dropdown-menu-item-group-title {
  display: inline-block;
  line-height: inherit !important;
  font-size: 14px !important;
  margin-top: 10px;
}

//ant-select placeholder cursor overlap
.ant-select {
  .ant-select-selection__placeholder, .ant-select-selection-selected-value {
    margin-left: 4px !important;
  }
}
.profile-form-view {
  .ant-form-item-label {
    text-align: left !important;
    width: 200px;
  }
  .view-change-request {
    display: flex;
    justify-content: space-evenly;
    align-content: flex-start;
    flex-direction: row;
    .change-request-data {
      width: 200px;
      word-break: break-word;
      line-height: 2.5;
      padding: 0 10px 0 10px;
    }
    .table-action-btn {
      display: flex;
      align-items: baseline;
    }
  }
}
.inside-section {
  border-bottom: 1px solid $border-color;
  border-top: 1px solid $border-color;
  padding: 10px 0px;
  .header{
    font-size: 16px;
    color: $primary;
    padding: 0px 10px;
  }
  .ant-pagination {
    display: flex;
    justify-content: center;
    margin: 20px auto;
  }
  .witness-details {
    margin-top: 24px;
    .parent-step {
      justify-content: flex-start !important;
      margin: 5px auto auto 5px;
    }
  }
}
.change-request-modal {
  display: flex;
  justify-content: space-around;
  word-break: break-word;
  div {
    width: 200px;
    text-align: center;
    &:first-child {
      padding-right: 5px;
    }
    a {
      display: inline-block;
      width: 100%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
.change-request-modal-errors {
  .anticon {
    margin-right: 5px;
    position: relative;
    top: 2px;
  }
  color: $error-color;
  display: flex;
  align-items: baseline;
  margin: 0 0 10px 10px;
}

.close-active-status-popup {
  .ant-col:first-child {
    text-align: end;
    padding-right: 15px;
  }
  .ant-col:last-child {
    color: rgba(0, 0, 0, 0.45);
  }
}

.notes-add {
  border: none !important;
  color: $primary !important;
  cursor: pointer;
  position: absolute !important;
  padding: 0;
  box-shadow: none !important;
  top: -33px;
  right: 0;
  z-index: 1;
  &[disabled] {
    background: none !important;
    cursor: default;
  }
}

.view-change-request {
  .empty-icon {
    margin-left: 30px;
  }
}

.modal-btn-hide {
  display: none !important;
}

.benefit-modal-header {
  display: flex;
  flex-direction: column;
  font-size: 16px;
  gap: 10px;
  border-bottom: 1px solid $border-color;
  padding-bottom: 20px;
  color: $primary;
  .title-value {
    display: flex;
    div:last-child {
      color: $label-text-color;
    }
    div:first-child {
      width: 160px;
    }
  }
}

.poly-notes-modified-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 150px;
  overflow: scroll;
  .poly-notes {
    span {
      color: $primary;
    }
  }
}

.poly-notes-modified-data {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  max-height: 150px;
  overflow: scroll;
  .poly-notes {
    span {
      color: $primary;
    }
  }
}

.witness-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  button {
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    box-shadow: none;
  }
}

.step-navigation {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap:10px;
  margin-top: 10px;
  button {
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 10px;
    i {
      margin-top: 2px;
    }
  }
}

.parent-step {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  .steps-count {
    border: 1px solid grey;
    padding: 1px 8px;
    border-radius: 50%;
    cursor: pointer;
  }
  .active {
    background-color: $primary;
    color: white;
    border: none;
  }
}

.contact-statuses {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
  .status-action {
    display: flex;
    gap: 10px;
  }
}

.public-DraftStyleDefault-block {
  margin: 0 !important;
}

.rdw-editor-main {
  border: 1px solid $border-color;
  min-height: 200px;
  padding: 10px;
  border-radius: 5px;
}

.editor-content {
  border: 1.5px solid $border-color;
  .rdw-editor-main {
    border-top: none;
    padding: 10px;
  }

  .rdw-editor-toolbar {
    position: sticky;
    top: 0;
    background-color: white;
    border: none;
    border-bottom: 1.5px solid $border-color !important;
    margin-bottom: 0 !important;
  }
  .DraftEditor-root {
    line-height: 1.5;
  }
}
.rdw-colorpicker-modal {
  height: 250px !important;
  width: 220px !important;
  .rdw-colorpicker-modal-options {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
@media (min-width: 1200px) {
  .rdw-colorpicker-modal {
    left: -120px !important;
  }
}

@media (max-width: 1440px) {
  .rdw-link-modal {
    left: 5px !important;
  }
}

@media (min-width: 1440px) {
  .rdw-link-modal {
    left: -160px !important;
  }
}
.rdw-link-modal {
  height: 300px !important;
  overflow: auto;
  .rdw-link-modal-buttonsection {
    display: flex;
    gap: 20px;
    button {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
.rdw-embedded-modal {
  height: 280px !important;
  z-index: 5 !important;
  overflow: auto;
  .ant-form-item-control {
    overflow: auto;
  }
  .rdw-embedded-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.rdw-emoji-modal {
  height: 230px !important;
}

.rdw-image-modal {
  height: 230px !important;
  padding: 5px 15px !important;
  z-index: 5 !important;
  overflow: auto;
  left: -45px !important;
  .rdw-image-modal-btn-section {
    display: flex;
    gap: 20px;
  }
  .rdw-image-modal-btn {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .rdw-image-modal-header {
    margin: 0 !important;
  }
}
.form-item-customize {
  .ant-form-item-control {
    line-height: 1.5 !important;
  }
}
.public-DraftStyleDefault-ltr {
  direction: inherit !important;
  text-align: inherit !important;
}

.rdw-dropdown-optionwrapper {
  max-height: 405px !important;
  .rdw-fontsize-option {
    line-height: 1.8 !important;
  }
}

.user-audit-main-container {
  .title {
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 15px;
  }
  .user-audit-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    div {
      width: 190px;
      padding-bottom: 15px;
    }
    .audit-title {
      font-size: 18px;
      font-weight: 500;
      color: $primary;
    }
  }
  .no-changes-found {
    margin: auto;
    text-align: center;
  }
}

.report-audit {
  font-size: medium;
  font-weight: 400;
}

.edit-disciplines-tab-modal {
  .ant-modal-body {
    padding: 44px 24px !important;
  }
}

.user-audit-name, .user-audit-benefits {
  display: flex;
  margin: 0 0 15px 15px;
  width: 500px;
  font-size: 16px;
  padding: 5px 10px;
  background: $border-color;
  border: 1px solid $border-color;
  border-radius: 5px;
  div:first-child {
    width: 40%;
  }
  div:nth-child(2) {
    width: 10%;
  }
  div:last-child {
    width: 60%;
  }
}
.benefits-title {
  color: $primary;
  font-weight: 500;
}

.firearm-forms {
  display: flex;
  gap: 30px;
  div {
    display: flex;
  }
  .add-edit-firmarm {
    color: $primary;
    display: flex;
    align-items: center;
    span {
      padding: 0 10px;
      cursor: pointer;
    }
  }
}

.spin-loader {
  display: flex;
  justify-content: center;
  align-items: center;
}

.grievance-charge-add {
  cursor: pointer;
  text-align: end;
  color: $primary;
}

.audit-container-header {
  display: flex;
  .date-filter {
    margin-left: 10px;
    .ant-calendar-picker-input{
     width: 210px;
    }
  }
}
.no-transition-collapse{
  .ant-collapse-content {
    transition: height 0s !important;
  }
}
@media print{
  @page {
    margin: 30px !important;
  }
  .ant-col {
    line-height:17px !important;
    font-weight: 600;
    width: -webkit-fill-available !important;
  }
  .ant-form-item-control {
    padding-left: 5px !important;
  }
  .ant-row {
    display: block !important;
  }
  .hide-on-print {
    overflow: hidden !important;
    height: 0 !important;
    display: none !important;
  }
}